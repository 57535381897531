<template>
  <v-container>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="480px"
      :scrollable="$vuetify.breakpoint.smAndDown"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-card flat tile class="pa-2">
        <v-img
          contain
          v-if="itemSelected.picture"
          aspect-ratio="2"
          class="white"
          height="40vh"
          :src="
            getImagePhoto(itemSelected.account, itemSelected.store, itemSelected.picture)
          "
        >
          <v-app-bar flat color="transparent">
            <v-spacer></v-spacer>
            <v-btn fab icon dark color="success" @click="close()">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-app-bar>
        </v-img>
        <v-card-title v-else>
          <v-app-bar flat dense color="transparent">
            <v-spacer></v-spacer>
            <v-btn fab x-small top right @click="close()">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-app-bar>
        </v-card-title>

        <v-card-title class="pt-0">
          <b>{{ itemSelected.product_name }} </b>
        </v-card-title>
        <v-card-text>
          <span v-html="itemSelected.description"></span>
          <template>
            <v-row>
              <v-col
                cols="12"
                v-for="(value, key) in itemSelected.product_options"
                :key="key"
                justify="center"
              >
                <h3 v-if="selecttype(itemSelected.product_options[key])">
                  Seleccione la {{ key }} que desee
                </h3>
                <h3 v-else>Seleccione los {{ key }} que desee</h3>
                <v-data-table
                  :headers="header_selected"
                  hide-default-header
                  hide-default-footer
                  :items-per-page="-1"
                  mobile-breakpoint="0"
                  :items="itemSelected.product_options[key]"
                  item-key="product_name"
                  :single-select="selecttype(itemSelected.product_options[key])"
                  class="elevation-1"
                  dense
                >
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-checkbox v-model="item.selected" />
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </template>
          <v-textarea
            v-model="itemSelected.note"
            class="pt-12"
            auto-grow
            label="Note"
            rows="1"
          />
        </v-card-text>

        <v-card-actions class="pt-0">
          <span>
            Pack: {{ itemSelected.size * itemSelected.pack }} {{ itemSelected.uom }}</span
          >
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            class="text-decoration-line-through"
            v-if="itemSelected.discount !== 0"
          >
            {{
              "$" + (parseFloat(itemSelected.price) * parseFloat(itemSelected.pack)* parseFloat(itemSelected.qty)).toLocaleString()
            }}
          </v-btn>
        </v-card-actions>

        <v-card-actions>
          <v-row>
            <v-col cols="5">
              <v-text-field
                v-model="itemSelected.qty"
                class="center-input"
                hide-details
                filled
                rounded
                Label="Qty"
                dense
                single-line
                prepend-inner-icon="mdi-minus"
                append-icon="mdi-plus"
                @click:prepend-inner="restQty"
                @click:append="addQty"
              />
            </v-col>
            <v-col cols="7">
              <v-btn v-if="addItm" block color="success" @click="add_cart(itemSelected)">
                {{ parseFloat(itemSelected.tItem).toLocaleString() }}
                <v-icon right> mdi-cart </v-icon>
              </v-btn>
              <v-btn v-else block large color="success" @click="updateCart(itemSelected)">
                {{ parseFloat(itemSelected.tItem).toLocaleString() }}
                <v-icon> mdi-cart </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { checkUTF8 } from "../services/webserver.js";
export default {
  name: "CartAdd",
  components: {},
  props: {
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    itemSelected: {
      type: Object,
      required: true,
    },
    addItm: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  mounted() {
    //this.updateTotals();
    console.log(this.itemSelected);
  },
  data() {
    return {
      options_selected: null,
      optionsLst: {},
      optSelected: [],
      value_options: 0,
      cart: [],
      header_selected: [
        {
          text: "",
          align: "start",
          value: "product_name",
        },
        {
          text: "",
          align: "end",
          value: "price",
          width: 20,
        },
        {
          align: "end",
          width: 20,
          text: "",
          value: "actions",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    add2selected(data) {
      var e;
      if (data.value) {
        e = data.item;
        this.value_options = 0;
        if (e.type === "Multiple") {
          this.optSelected.push({ ...e });
        } else {
          this.optSelected.forEach((rg, index) => {
            if (rg.category === e.category) {
              this.optSelected.splice(index, 1);
            }
          });
          this.optSelected.push({ ...e });
        }
        this.optSelected.forEach((rg) => {
          rg.price = parseFloat(rg.price);
          this.value_options += rg.price;
        });
      } else {
        e = data.item;
        this.optSelected.forEach((rg, index) => {
          if (rg.category === e.category && rg.product_name === e.product_name) {
            this.optSelected.splice(index, 1);
          }
        });
      }
      this.value_options = 0;
      this.optSelected.forEach((rg) => {
        rg.price = parseFloat(rg.price);
        this.value_options += rg.price;
      });
    },
    deleteSelected(e) {
      var index = this.optSelected.indexOf(e);
      if (index !== -1) {
        this.optSelected.splice(index, 1);
      }
      this.value_options = 0;
      this.optSelected.forEach((rg) => {
        rg.price = parseFloat(rg.price);
        this.value_options += rg.price;
      });
    },
    close() {
      this.$emit("close", true);
    },
    deleteItem(e) {
      var index = this.cart.indexOf(e);
      if (index !== -1) {
        this.cart.splice(index, 1);
      }
      this.updateTotals();
    },
    restQty() {
      if (this.itemSelected.qty > 1) {
        this.itemSelected.qty = this.itemSelected.qty - 1;
        this.itemSelected.tItem =
          parseFloat(this.itemSelected.qty) *
          parseFloat(this.itemSelected.pack) *
          (parseFloat(this.itemSelected.price) - parseFloat(this.itemSelected.discount));
      }
    },
    addQty() {
      this.itemSelected.qty = this.itemSelected.qty + 1;
      this.itemSelected.tItem =
        parseFloat(this.itemSelected.qty) *
        parseFloat(this.itemSelected.pack) *
        (parseFloat(this.itemSelected.price) - parseFloat(this.itemSelected.discount));
    },
    selecttype(e) {
      return e[0].unique;
    },
    getImagePhoto: function (account, store, src) {
      var url = "https://mayorista.app/photos/" + account + "/000000/" + src;
      return url;
    },
    add_cart(e) {
      e.description = checkUTF8(e.description);
      e.note = checkUTF8(e.note);
      if (localStorage.cart) {
        this.cart = JSON.parse(Buffer.from(localStorage.cart, "base64"));
        this.cart.forEach((itm) => {
          itm.qty = parseFloat(itm.qty);
          itm.price = parseFloat(itm.price);

          if (itm.value_options) {
            itm.value_options = parseFloat(itm.value_options);
          } else {
            itm.value_options = 0;
          }
          itm.tItem =
            parseFloat(itm.qty) *
            parseFloat(itm.pack) *
            (parseFloat(itm.price) - parseFloat(itm.discount));
        });
      } else {
        this.cart = [];
      }
      if (e.product_options) {
        var slt = e.product_options;
        var tselected = 0;
        Object.keys(slt).forEach((key) => {
          slt[key].forEach((tm) => {
            if (tm.selected) {
              tselected += parseFloat(tm.price);
            }
          });
        });
        e.value_options = tselected;
      } else {
        e.value_options = 0;
      }

      this.cart.push({ ...e });
      localStorage.cart = Buffer.from(JSON.stringify(this.cart)).toString("base64");
      this.close();
    },
    updateCart(e) {
      e.description = checkUTF8(e.description);
      e.note = checkUTF8(e.note);
      if (e.product_options) {
        var slt = e.product_options;
        var tselected = 0;
        Object.keys(slt).forEach((key) => {
          slt[key].forEach((tm) => {
            if (tm.selected) {
              tselected += parseFloat(tm.price);
            }
          });
        });
        e.value_options = tselected;
      } else {
        e.value_options = 0;
      }


      if (localStorage.cart) {
        this.cart = JSON.parse(Buffer.from(localStorage.cart, "base64"));
        this.cart.forEach((itm) => {
          itm.qty = parseFloat(itm.qty);
          itm.price = parseFloat(itm.price);
          if (itm.value_options) {
            itm.value_options = parseFloat(itm.value_options);
          } else {
            itm.value_options = 0;
          }
        });

        this.cart.find((item, i) => {
          if (item.code === e.code) {
            this.cart[i] = e;
          }
        });
        localStorage.cart = Buffer.from(JSON.stringify(this.cart)).toString("base64");
      }
      this.close();
    },
    updateTotals() {
      if (localStorage.cart) {
        this.cart = JSON.parse(Buffer.from(localStorage.cart, "base64"));
        this.cart.forEach((itm) => {
          itm.qty = parseFloat(itm.qty);
          itm.price = parseFloat(itm.price);
          if (itm.value_options) {
            itm.value_options = parseFloat(itm.value_options);
          } else {
            itm.value_options = 0;
          }
        });
        // this.updateTotals();
      } else {
        this.cart = [];
      }
      var itms = this.cart;
      var qty = 0;
      var tax = 0;
      var total = 0;
      itms.forEach((itm) => {
        qty += parseFloat(itm.qty);

        total += parseFloat(itm.tItem);
        tax += (parseFloat(itm.tItem) * parseFloat(itm.tax)) / 100;
      });
      this.sc.qty = qty;
      this.sc.subtotal = 0;
      this.sc.discount = 0;
      this.sc.tax = tax;
      this.sc.total = total;
    },
  },
  watch: {
    item: function (itm) {
      console.log(itm);
      itm.tItem =
        parseFloat(itm.qty) *
        parseFloat(itm.pack) *
        (parseFloat(itm.price) - parseFloat(itm.discount));
    },
  },
};
</script>

<style>
.center-input input {
  text-align: center !important;
}
</style>
